const countryCodeList = [{
    "countryCode": "AF",
    "CountryCallingCode": '+93'
  },
  {
    "countryCode": "AX",
    "CountryCallingCode": '+358'
  },
  {
    "countryCode": "AL",
    "CountryCallingCode": '+355'
  },
  {
    "countryCode": "DZ",
    "CountryCallingCode": '+213'
  },
  {
    "countryCode": "AS",
    "CountryCallingCode": '+1684'
  },
  {
    "countryCode": "AD",
    "CountryCallingCode": '+376'
  },
  {
    "countryCode": "AO",
    "CountryCallingCode": '+244'
  },
  {
    "countryCode": "AI",
    "CountryCallingCode": '+1264'
  },
  {
    "countryCode": "AQ",
    "CountryCallingCode": '+672'
  },
  {
    "countryCode": "AG",
    "CountryCallingCode": '+1268'
  },
  {
    "countryCode": "AR",
    "CountryCallingCode": '+54'
  },
  {
    "countryCode": "AM",
    "CountryCallingCode": '+374'
  },
  {
    "countryCode": "AW",
    "CountryCallingCode": '+297'
  },
  {
    "countryCode": "AU",
    "CountryCallingCode": '+61'
  },
  {
    "countryCode": "AT",
    "CountryCallingCode": '+43'
  },
  {
    "countryCode": "AZ",
    "CountryCallingCode": '+994'
  },
  {
    "countryCode": "BS",
    "CountryCallingCode": '+1242'
  },
  {
    "countryCode": "BH",
    "CountryCallingCode": '+973'
  },
  {
    "countryCode": "BD",
    "CountryCallingCode": '+880'
  },
  {
    "countryCode": "BB",
    "CountryCallingCode": '+1246'
  },
  {
    "countryCode": "BY",
    "CountryCallingCode": '+375'
  },
  {
    "countryCode": "BE",
    "CountryCallingCode": '+32'
  },
  {
    "countryCode": "BZ",
    "CountryCallingCode": '+501'
  },
  {
    "countryCode": "BJ",
    "CountryCallingCode": '+229'
  },
  {
    "countryCode": "BM",
    "CountryCallingCode": '+1441'
  },
  {
    "countryCode": "BT",
    "CountryCallingCode": '+975'
  },
  {
    "countryCode": "BO",
    "CountryCallingCode": '+591'
  },
  {
    "countryCode": "BQ",
    "CountryCallingCode": '+599'
  },
  {
    "countryCode": "BA",
    "CountryCallingCode": '+387'
  },
  {
    "countryCode": "BW",
    "CountryCallingCode": '+267'
  },
  {
    "countryCode": "BV",
    "CountryCallingCode": '+55'
  },
  {
    "countryCode": "BR",
    "CountryCallingCode": '+55'
  },
  {
    "countryCode": "IO",
    "CountryCallingCode": '+246'
  },
  {
    "countryCode": "BN",
    "CountryCallingCode": '+673'
  },
  {
    "countryCode": "BG",
    "CountryCallingCode": '+359'
  },
  {
    "countryCode": "BF",
    "CountryCallingCode": '+226'
  },
  {
    "countryCode": "BI",
    "CountryCallingCode": '+257'
  },
  {
    "countryCode": "KH",
    "CountryCallingCode": '+855'
  },
  {
    "countryCode": "CM",
    "CountryCallingCode": '+237'
  },
  // {
  //   "countryCode": "CA",
  //   "CountryCallingCode": '+1'
  // },
  {
    "countryCode": "CV",
    "CountryCallingCode": '+238'
  },
  {
    "countryCode": "KY",
    "CountryCallingCode": '+1345'
  },
  {
    "countryCode": "CF",
    "CountryCallingCode": '+236'
  },
  {
    "countryCode": "TD",
    "CountryCallingCode": '+235'
  },
  {
    "countryCode": "CL",
    "CountryCallingCode": '+56'
  },
  {
    "countryCode": "CN",
    "CountryCallingCode": '+86'
  },
  {
    "countryCode": "CX",
    "CountryCallingCode": '+61'
  },
  {
    "countryCode": "CC",
    "CountryCallingCode": '+672'
  },
  {
    "countryCode": "CO",
    "CountryCallingCode": '+57'
  },
  {
    "countryCode": "KM",
    "CountryCallingCode": '+269'
  },
  {
    "countryCode": "CG",
    "CountryCallingCode": '+242'
  },
  {
    "countryCode": "CD",
    "CountryCallingCode": '+242'
  },
  {
    "countryCode": "CK",
    "CountryCallingCode": '+682'
  },
  {
    "countryCode": "CR",
    "CountryCallingCode": '+506'
  },
  {
    "countryCode": "CI",
    "CountryCallingCode": '+225'
  },
  {
    "countryCode": "HR",
    "CountryCallingCode": '+385'
  },
  {
    "countryCode": "CU",
    "CountryCallingCode": '+53'
  },
  {
    "countryCode": "CW",
    "CountryCallingCode": '+599'
  },
  {
    "countryCode": "CY",
    "CountryCallingCode": '+357'
  },
  {
    "countryCode": "CZ",
    "CountryCallingCode": '+420'
  },
  {
    "countryCode": "DK",
    "CountryCallingCode": '+45'
  },
  {
    "countryCode": "DJ",
    "CountryCallingCode": '+253'
  },
  {
    "countryCode": "DM",
    "CountryCallingCode": '+1767'
  },
  {
    "countryCode": "DO",
    "CountryCallingCode": '+1809'
  },
  {
    "countryCode": "EC",
    "CountryCallingCode": '+593'
  },
  {
    "countryCode": "EG",
    "CountryCallingCode": '+20'
  },
  {
    "countryCode": "SV",
    "CountryCallingCode": '+503'
  },
  {
    "countryCode": "GQ",
    "CountryCallingCode": '+240'
  },
  {
    "countryCode": "ER",
    "CountryCallingCode": '+291'
  },
  {
    "countryCode": "EE",
    "CountryCallingCode": '+372'
  },
  {
    "countryCode": "ET",
    "CountryCallingCode": '+251'
  },
  {
    "countryCode": "FK",
    "CountryCallingCode": '+500'
  },
  {
    "countryCode": "FO",
    "CountryCallingCode": '+298'
  },
  {
    "countryCode": "FJ",
    "CountryCallingCode": '+679'
  },
  {
    "countryCode": "FI",
    "CountryCallingCode": '+358'
  },
  {
    "countryCode": "FR",
    "CountryCallingCode": '+33'
  },
  {
    "countryCode": "GF",
    "CountryCallingCode": '+594'
  },
  {
    "countryCode": "PF",
    "CountryCallingCode": '+689'
  },
  {
    "countryCode": "TF",
    "CountryCallingCode": '+262'
  },
  {
    "countryCode": "GA",
    "CountryCallingCode": '+241'
  },
  {
    "countryCode": "GM",
    "CountryCallingCode": '+220'
  },
  {
    "countryCode": "GE",
    "CountryCallingCode": '+995'
  },
  {
    "countryCode": "DE",
    "CountryCallingCode": '+49'
  },
  {
    "countryCode": "GH",
    "CountryCallingCode": '+233'
  },
  {
    "countryCode": "GI",
    "CountryCallingCode": '+350'
  },
  {
    "countryCode": "GR",
    "CountryCallingCode": '+30'
  },
  {
    "countryCode": "GL",
    "CountryCallingCode": '+299'
  },
  {
    "countryCode": "GD",
    "CountryCallingCode": '+1473'
  },

  {
    "countryCode": "GP",
    "CountryCallingCode": '+590'
  },
  {
    "countryCode": "GU",
    "CountryCallingCode": '+1671'
  },
  {
    "countryCode": "GT",
    "CountryCallingCode": '+502'
  },
  {
    "countryCode": "GG",
    "CountryCallingCode": '+44'
  },
  {
    "countryCode": "GN",
    "CountryCallingCode": '+224'
  },
  {
    "countryCode": "GW",
    "CountryCallingCode": '+245'
  },
  {
    "countryCode": "GY",
    "CountryCallingCode": '+592'
  },
  {
    "countryCode": "HT",
    "CountryCallingCode": '+509'
  },
  {
    "countryCode": "HM",
    "CountryCallingCode": '+0'
  },
  {
    "countryCode": "VA",
    "CountryCallingCode": '+39'
  },
  {
    "countryCode": "HN",
    "CountryCallingCode": '+504'
  },
  {
    "countryCode": "HK",
    "CountryCallingCode": '+852'
  },
  {
    "countryCode": "HU",
    "CountryCallingCode": '+36'
  },
  {
    "countryCode": "IS",
    "CountryCallingCode": '+354'
  },
  {
    "countryCode": "IN",
    "CountryCallingCode": '+91'
  },
  {
    "countryCode": "ID",
    "CountryCallingCode": '+62'
  },
  {
    "countryCode": "IR",
    "CountryCallingCode": '+98'
  },
  {
    "countryCode": "IQ",
    "CountryCallingCode": '+964'
  },
  {
    "countryCode": "IE",
    "CountryCallingCode": '+353'
  },
  {
    "countryCode": "IM",
    "CountryCallingCode": '+44'
  },
  {
    "countryCode": "IL",
    "CountryCallingCode": '+972'
  },
  {
    "countryCode": "IT",
    "CountryCallingCode": '+39'
  },
  {
    "countryCode": "JM",
    "CountryCallingCode": '+1876'
  },
  {
    "countryCode": "JP",
    "CountryCallingCode": '+81'
  },
  {
    "countryCode": "JE",
    "CountryCallingCode": '+44'
  },
  {
    "countryCode": "JO",
    "CountryCallingCode": '+962'
  },
  {
    "countryCode": "KZ",
    "CountryCallingCode": '+7'
  },
  {
    "countryCode": "KE",
    "CountryCallingCode": '+254'
  },
  {
    "countryCode": "KI",
    "CountryCallingCode": '+686'
  },
  {
    "countryCode": "KP",
    "CountryCallingCode": '+850'
  },
  {
    "countryCode": "KR",
    "CountryCallingCode": '+82'
  },
  {
    "countryCode": "XK",
    "CountryCallingCode": '+381'
  },
  {
    "countryCode": "KW",
    "CountryCallingCode": '+965'
  },
  {
    "countryCode": "KG",
    "CountryCallingCode": '+996'
  },
  {
    "countryCode": "LA",
    "CountryCallingCode": '+856'
  },
  {
    "countryCode": "LV",
    "CountryCallingCode": '+371'
  },
  {
    "countryCode": "LB",
    "CountryCallingCode": '+961'
  },
  {
    "countryCode": "LS",
    "CountryCallingCode": '+266'
  },
  {
    "countryCode": "LR",
    "CountryCallingCode": '+231'
  },
  {
    "countryCode": "LY",
    "CountryCallingCode": '+218'
  },
  {
    "countryCode": "LI",
    "CountryCallingCode": '+423'
  },
  {
    "countryCode": "LT",
    "CountryCallingCode": '+370'
  },
  {
    "countryCode": "LU",
    "CountryCallingCode": '+352'
  },
  {
    "countryCode": "MO",
    "CountryCallingCode'+": '+853'
  },
  {
    "countryCode": "MK",
    "CountryCallingCode": '+389'
  },
  {
    "countryCode": "MG",
    "CountryCallingCode": '+261'
  },
  {
    "countryCode": "MW",
    "CountryCallingCode": '+265'
  },
  {
    "countryCode": "MY",
    "CountryCallingCode": '+60'
  },
  {
    "countryCode": "MV",
    "CountryCallingCode": '+960'
  },
  {
    "countryCode": "ML",
    "CountryCallingCode": '+223'
  },
  {
    "countryCode": "MT",
    "CountryCallingCode": '+356'
  },
  {
    "countryCode": "MH",
    "CountryCallingCode": '+692'
  },
  {
    "countryCode": "MQ",
    "CountryCallingCode": '+596'
  },
  {
    "countryCode": "MR",
    "CountryCallingCode": '+222'
  },
  {
    "countryCode": "MU",
    "CountryCallingCode": '+230'
  },
  {
    "countryCode": "YT",
    "CountryCallingCode": '+269'
  },
  {
    "countryCode": "MX",
    "CountryCallingCode": '+52'
  },
  {
    "countryCode": "FM",
    "CountryCallingCode": '+691'
  },
  {
    "countryCode": "MD",
    "CountryCallingCode": '+373'
  },
  {
    "countryCode": "MC",
    "CountryCallingCode": '+377'
  },
  {
    "countryCode": "MN",
    "CountryCallingCode": '+976'
  },
  {
    "countryCode": "ME",
    "CountryCallingCode": '+382'
  },
  {
    "countryCode": "MS",
    "CountryCallingCode": '+1664'
  },
  {
    "countryCode": "MA",
    "CountryCallingCode": '+212'
  },
  {
    "countryCode": "MZ",
    "CountryCallingCode": '+258'
  },
  {
    "countryCode": "MM",
    "CountryCallingCode": '+95'
  },
  {
    "countryCode": "NA",
    "CountryCallingCode": '+264'
  },
  {
    "countryCode": "NR",
    "CountryCallingCode": '+674'
  },
  {
    "countryCode": "NP",
    "CountryCallingCode": '+977'
  },
  {
    "countryCode": "NL",
    "CountryCallingCode": '+31'
  },
  {
    "countryCode": "AN",
    "CountryCallingCode": '+599'
  },
  {
    "countryCode": "NC",
    "CountryCallingCode": '+687'
  },
  {
    "countryCode": "NZ",
    "CountryCallingCode": '+64'
  },
  {
    "countryCode": "NI",
    "CountryCallingCode": '+505'
  },
  {
    "countryCode": "NE",
    "CountryCallingCode": '+227'
  },
  {
    "countryCode": "NG",
    "CountryCallingCode": '+234'
  },
  {
    "countryCode": "NU",
    "CountryCallingCode": '+683'
  },
  {
    "countryCode": "NF",
    "CountryCallingCode": '+672'
  },
  {
    "countryCode": "MP",
    "CountryCallingCode": '+1670'
  },
  {
    "countryCode": "NO",
    "CountryCallingCode": '+47'
  },
  {
    "countryCode": "OM",
    "CountryCallingCode": '+968'
  },
  {
    "countryCode": "PK",
    "CountryCallingCode": '+92'
  },
  {
    "countryCode": "PW",
    "CountryCallingCode": '+680'
  },
  {
    "countryCode": "PS",
    "CountryCallingCode": '+970'
  },
  {
    "countryCode": "PA",
    "CountryCallingCode": '+507'
  },
  {
    "countryCode": "PG",
    "CountryCallingCode": '+675'
  },
  {
    "countryCode": "PY",
    "CountryCallingCode": '+595'
  },
  {
    "countryCode": "PE",
    "CountryCallingCode": '+51'
  },
  {
    "countryCode": "PH",
    "CountryCallingCode": '+63'
  },
  {
    "countryCode": "PN",
    "CountryCallingCode": '+64'
  },
  {
    "countryCode": "PL",
    "CountryCallingCode": '+48'
  },
  {
    "countryCode": "PT",
    "CountryCallingCode": '+351'
  },
  {
    "countryCode": "PR",
    "CountryCallingCode": '+1787'
  },
  {
    "countryCode": "QA",
    "CountryCallingCode": '+974'
  },
  {
    "countryCode": "RE",
    "CountryCallingCode": '+262'
  },
  {
    "countryCode": "RO",
    "CountryCallingCode": '+40'
  },
  {
    "countryCode": "RU",
    "CountryCallingCode": '+70'
  },
  {
    "countryCode": "RW",
    "CountryCallingCode": '+250'
  },
  {
    "countryCode": "BL",
    "CountryCallingCode": '+590'
  },
  {
    "countryCode": "SH",
    "CountryCallingCode": '+290'
  },
  {
    "countryCode": "KN",
    "CountryCallingCode": '+1869'
  },
  {
    "countryCode": "LC",
    "CountryCallingCode": '+1758'
  },
  {
    "countryCode": "MF",
    "CountryCallingCode": '+590'
  },
  {
    "countryCode": "PM",
    "CountryCallingCode": '+508'
  },
  {
    "countryCode": "VC",
    "CountryCallingCode": '+1784'
  },
  {
    "countryCode": "WS",
    "CountryCallingCode": '+684'
  },
  {
    "countryCode": "SM",
    "CountryCallingCode": '+378'
  },
  {
    "countryCode": "ST",
    "CountryCallingCode": '+239'
  },
  {
    "countryCode": "SA",
    "CountryCallingCode": '+966'
  },
  {
    "countryCode": "SN",
    "CountryCallingCode": '+221'
  },
  {
    "countryCode": "RS",
    "CountryCallingCode": '+381'
  },
  {
    "countryCode": "CS",
    "CountryCallingCode": '+381'
  },
  {
    "countryCode": "SC",
    "CountryCallingCode": '+248'
  },
  {
    "countryCode": "SL",
    "CountryCallingCode": '+232'
  },
  {
    "countryCode": "SG",
    "CountryCallingCode": '+65'
  },
  // {
  //   "countryCode": "SX",
  //   "CountryCallingCode": '+1'
  // },
  {
    "countryCode": "SK",
    "CountryCallingCode": '+421'
  },
  {
    "countryCode": "SI",
    "CountryCallingCode": '+386'
  },
  {
    "countryCode": "SB",
    "CountryCallingCode": '+677'
  },
  {
    "countryCode": "SO",
    "CountryCallingCode": '+252'
  },
  {
    "countryCode": "ZA",
    "CountryCallingCode": '+27'
  },
  {
    "countryCode": "GS",
    "CountryCallingCode": '+500'
  },
  {
    "countryCode": "SS",
    "CountryCallingCode": '+211'
  },
  {
    "countryCode": "ES",
    "CountryCallingCode": '+34'
  },
  {
    "countryCode": "LK",
    "CountryCallingCode": '+94'
  },
  {
    "countryCode": "SD",
    "CountryCallingCode": '+249'
  },
  {
    "countryCode": "SR",
    "CountryCallingCode": '+597'
  },
  {
    "countryCode": "SJ",
    "CountryCallingCode": '+47'
  },
  {
    "countryCode": "SZ",
    "CountryCallingCode": '+268'
  },
  {
    "countryCode": "SE",
    "CountryCallingCode": '+46'
  },
  {
    "countryCode": "CH",
    "CountryCallingCode": '+41'
  },
  {
    "countryCode": "SY",
    "CountryCallingCode": '+963'
  },
  {
    "countryCode": "TW",
    "CountryCallingCode": '+886'
  },
  {
    "countryCode": "TJ",
    "CountryCallingCode": '+992'
  },
  {
    "countryCode": "TZ",
    "CountryCallingCode": '+255'
  },
  {
    "countryCode": "TH",
    "CountryCallingCode": '+66'
  },
  {
    "countryCode": "TL",
    "CountryCallingCode": '+670'
  },
  {
    "countryCode": "TG",
    "CountryCallingCode": '+228'
  },
  {
    "countryCode": "TK",
    "CountryCallingCode": '+690'
  },
  {
    "countryCode": "TO",
    "CountryCallingCode": '+676'
  },
  {
    "countryCode": "TT",
    "CountryCallingCode": '+1868'
  },
  {
    "countryCode": "TN",
    "CountryCallingCode": '+216'
  },
  {
    "countryCode": "TR",
    "CountryCallingCode": '+90'
  },
  {
    "countryCode": "TM",
    "CountryCallingCode": '+7370'
  },
  {
    "countryCode": "TC",
    "CountryCallingCode": '+1649'
  },
  {
    "countryCode": "TV",
    "CountryCallingCode": '+688'
  },
  {
    "countryCode": "UG",
    "CountryCallingCode": '+256'
  },
  {
    "countryCode": "UA",
    "CountryCallingCode": '+380'
  },
  {
    "countryCode": "AE",
    "CountryCallingCode": '+971'
  },
  {
    "countryCode": "GB",
    "CountryCallingCode": '+44'
  },
  {
    "countryCode": "US",
    "CountryCallingCode": '+1'
  },
  // {
  //   "countryCode": "UM",
  //   "CountryCallingCode": '+1'
  // },
  {
    "countryCode": "UY",
    "CountryCallingCode": '+598'
  },
  {
    "countryCode": "UZ",
    "CountryCallingCode": '+998'
  },
  {
    "countryCode": "VU",
    "CountryCallingCode": '+678'
  },
  {
    "countryCode": "VE",
    "CountryCallingCode": '+58'
  },
  {
    "countryCode": "VN",
    "CountryCallingCode": '+84'
  },
  {
    "countryCode": "VG",
    "CountryCallingCode": '+1284'
  },
  {
    "countryCode": "VI",
    "CountryCallingCode": '+1340'
  },
  {
    "countryCode": "WF",
    "CountryCallingCode": '+681'
  },
  {
    "countryCode": "EH",
    "CountryCallingCode": '+212'
  },
  {
    "countryCode": "YE",
    "CountryCallingCode": '+967'
  },
  {
    "countryCode": "ZM",
    "CountryCallingCode": '+260'
  },
  {
    "countryCode": "ZW",
    "CountryCallingCode": '+263'
  },
  {
    "countryCode": "ZM",
    "CountryCallingCode": '+260'
  },
  {
    "countryCode": "ZW",
    "CountryCallingCode": '+263'
  }
];



export default countryCodeList